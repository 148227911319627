// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUserCheck, 
       IconSelect, 
       IconSettings, 
       IconMessages, 
       IconLayoutKanban,
       IconMail, 
       IconCalendar, 
       IconNfc, 
       IconDeviceAnalytics,
       IconBrandBooking,
       IconFlag } from '@tabler/icons';

// constant
const icons = {
    IconUserCheck,
    IconSettings,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconSelect,
    IconDeviceAnalytics,
    IconBrandBooking,
    IconFlag
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application = {
    id: 'application',
    title: <FormattedMessage id="application" />,
    type: 'group',
    children: [
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            icon: icons.IconSettings,
            url: '/user/account-profile/profile3'
        },
        {
            id: 'teacher',
            title: <FormattedMessage id="teacher" />,
            type: 'collapse',
            icon: icons.IconUserCheck,
            children: [
                {
                    id: 'teacher-create',
                    title: <FormattedMessage id="teacher-create" />,
                    type: 'item',
                    url: '/teacher/create',
                    breadcrumbs: false
                },
                {
                    id: 'teacher-list',
                    title: <FormattedMessage id="teacher-list" />,
                    type: 'item',
                    url: '/teacher/list',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'booking',
            title: <FormattedMessage id="booking" />,
            type: 'collapse',
            icon: icons.IconBrandBooking,
            children: [
                {
                    id: 'trial-list',
                    title: <FormattedMessage id="trial-list" />,
                    type: 'item',
                    url: '/booking/trial-list',
                    breadcrumbs: true
                },
                {
                    id: 'subscription-list',
                    title: <FormattedMessage id="subscription-list" />,
                    type: 'item',
                    url: '/booking/subscription-list',
                    breadcrumbs: true
                },
                {
                    id: 'group-list',
                    title: <FormattedMessage id="group-list" />,
                    type: 'item',
                    url: '/booking/group-list',
                    breadcrumbs: true
                },
            ]
        },
        {
            id: 'class',
            title: <FormattedMessage id="class" />,
            type: 'collapse',
            icon: icons.IconDeviceAnalytics,
            children: [
                {
                    id: 'class-list',
                    title: <FormattedMessage id="class-list" />,
                    type: 'item',
                    url: '/class/list',
                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'chat',
        //     title: <FormattedMessage id="chat" />,
        //     type: 'item',
        //     icon: icons.IconMessages,
        //     url: '/app/chat'
        // },
        // {
        //     id: 'kanban',
        //     title: 'Kanban',
        //     type: 'item',
        //     icon: icons.IconLayoutKanban,
        //     url: '/app/kanban'
        // },
        // {
        //     id: 'mail',
        //     title: <FormattedMessage id="mail" />,
        //     type: 'item',
        //     icon: icons.IconMail,
        //     url: '/app/mail'
        // },
        {
            id: 'calendar',
            title: <FormattedMessage id="calendar" />,
            type: 'item',
            url: '/app/calendar',
            icon: icons.IconCalendar,
            breadcrumbs: false
        },
        {
            id: 'event',
            title: <FormattedMessage id="event" />,
            type: 'collapse',
            icon: icons.IconFlag,
            children: [
                {
                    id: 'event-create',
                    title: <FormattedMessage id="event-create" />,
                    type: 'item',
                    url: '/event/create',
                    breadcrumbs: false
                },
                {
                    id: 'event-list',
                    title: <FormattedMessage id="event-list" />,
                    type: 'item',
                    url: '/event/list',
                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'contact',
        //     title: <FormattedMessage id="contact" />,
        //     type: 'collapse',
        //     icon: icons.IconNfc,
        //     children: [
        //         {
        //             id: 'teacher',
        //             title: <FormattedMessage id="teacher" />,
        //             type: 'item',
        //             url: '/app/contact/teacher',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'student',
        //             title: <FormattedMessage id="student" />,
        //             type: 'item',
        //             url: '/app/contact/student',
        //             breadcrumbs: false
        //         }
        //     ]
        // }
        // {
        //     id: 'e-commerce',
        //     title: <FormattedMessage id="e-commerce" />,
        //     type: 'collapse',
        //     icon: icons.IconBasket,
        //     children: [
        //         {
        //             id: 'products',
        //             title: <FormattedMessage id="products" />,
        //             type: 'item',
        //             url: '/e-commerce/products'
        //         },
        //         {
        //             id: 'product-details',
        //             title: <FormattedMessage id="product-details" />,
        //             type: 'item',
        //             url: '/e-commerce/product-details/default',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'product-list',
        //             title: <FormattedMessage id="product-list" />,
        //             type: 'item',
        //             url: '/e-commerce/product-list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'checkout',
        //             title: <FormattedMessage id="checkout" />,
        //             type: 'item',
        //             url: '/e-commerce/checkout'
        //         }
        //     ]
        // }
    ]
};

export default application;
