import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import config from '../config';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
// import axios from 'utils/axios';
import authHeader from '../Services/auth-header'; 
import axios from 'axios';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    const response = await axios.get(`${config.API_SERVER}/api/organization/me`, { headers: authHeader() });
                    const user = response.data.organization;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                name: user.name,
                                contact: user.contact,
                                title: user.title,
                                logo: user.logo.url,
                                barcode: user.barcode? user.barcode.url : null,
                                email: user.email,
                                telephone: user.telephone,
                                website: user.website,
                                introduction: user.introduction,
                                street: user.address? user.address.street : null,
                                city: user.address? user.address.city : null,
                                state: user.address? user.address.state : null,
                                country: user.address? user.address.country : null,
                                postcode: user.address? user.address.postcode : null
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        const response = await axios.post(`${config.API_SERVER}/api/login/organization`, { email, password });
        // const { serviceToken, user } = response.data;
        const serviceToke  = response.data.access_token;
        const user = response.data.organization;
        console.log(response);
        setSession(serviceToke);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user: {
                    name: user.name,
                    contact: user.contact,
                    title: user.title,
                    logo: user.logo.url,
                    barcode: user.barcode? user.barcode.url : null,
                    email: user.email,
                    telephone: user.telephone,
                    website: user.website,
                    introduction: user.introduction,
                    street: user.address? user.address.street : null,
                    city: user.address? user.address.city : null,
                    state: user.address? user.address.state : null,
                    country: user.address? user.address.country : null,
                    postcode: user.address? user.address.postcode : null
                }
            }
        });
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = (email) => console.log(email);

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
